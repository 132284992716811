import {
  CartDispensary,
  DeliveryServiceArea,
} from "custom-types/CartDispensary";
import { ScheduleStatuses } from "custom-types/Dispensary";
import { WeeklySchedule } from "custom-types/DispensarySchedules";
import { HYDRATE, HydrateAction } from "redux/reducers/hydration";
import { clearSessionStorage } from "utils/sessionStorage";

export type CartDispensaryState = {
  scheduleStatuses: {
    currentStatuses: ScheduleStatuses;
    schedules: WeeklySchedule;
  } | null;
  data: CartDispensary | null;
  deliveryServiceArea: DeliveryServiceArea | null;
  deliveryServiceAreaLoading: boolean;
  menuLink: string | null;
  checkoutDisclaimer: string | null;
};

export const initialState: CartDispensaryState = {
  checkoutDisclaimer: null,
  data: null,
  deliveryServiceArea: null,
  deliveryServiceAreaLoading: false,
  menuLink: null,
  scheduleStatuses: null,
};

export const LOAD_CART_DISPENSARY = "cartDispensary/load";
export const LOAD_CHECKOUT_DISCLAIMER = "cartDispensary/loadDisclaimer";
export const SET_SCHEDULE_STATUSES = "cartDispensary/setCurrentStatuses";
export const SET_DELIVERY_SERVICE_AREA =
  "cartDispensary/setDeliveryServiceArea";
export const SET_MENU_LINK = "cartDispensary/setMenuLink";

export type GetCartDispensaryAction = {
  type: typeof LOAD_CART_DISPENSARY;
  data: CartDispensary | null;
};

export type SetCurrentStatusesAction = {
  type: typeof SET_SCHEDULE_STATUSES;
  currentStatuses: {
    currentStatuses: ScheduleStatuses;
    schedules: WeeklySchedule;
  } | null;
};

export type SetDeliveryServiceAreaAction = {
  type: typeof SET_DELIVERY_SERVICE_AREA;
  deliveryServiceArea: DeliveryServiceArea;
};

export type SetMenuLinkAction = {
  type: typeof SET_MENU_LINK;
  menuLink: string | null;
};

export type GetCheckoutDisclaimerAction = {
  type: typeof LOAD_CHECKOUT_DISCLAIMER;
  checkoutDisclaimer: null | string;
};

export type CartDispensaryActions =
  | GetCartDispensaryAction
  | GetCheckoutDisclaimerAction
  | SetCurrentStatusesAction
  | SetDeliveryServiceAreaAction
  | SetMenuLinkAction
  | HydrateAction;

export const cartDispensaryReducer = (
  state = initialState,
  action: CartDispensaryActions,
): CartDispensaryState => {
  switch (action.type) {
    case HYDRATE:
      /**
       * The `cartDispensary` state is modified in `getServerSideProps` of checkout
       * pages, and needs to be loaded here to ensure that data is added to the
       * redux state.
       */
      return {
        /**
         * Retain any state that was added client-side that isn't in the initial state.
         */
        ...state,
        ...action.payload.cartDispensary,
      };
    case LOAD_CART_DISPENSARY:
      return {
        ...state,
        data: action.data,
      };
    case LOAD_CHECKOUT_DISCLAIMER:
      return {
        ...state,
        checkoutDisclaimer: action.checkoutDisclaimer,
      };
    case SET_SCHEDULE_STATUSES:
      return {
        ...state,
        scheduleStatuses: action.currentStatuses,
      };
    case SET_DELIVERY_SERVICE_AREA:
      return {
        ...state,
        deliveryServiceArea: action.deliveryServiceArea,
      };
    case SET_MENU_LINK:
      if (!action.menuLink) {
        clearSessionStorage("referrer");
      }

      return {
        ...state,
        menuLink: action.menuLink,
      };
    default:
      return state;
  }
};

export default cartDispensaryReducer;
